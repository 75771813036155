import React, { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "./expired-token.styles.module.scss";
import { TypographyComponent } from "gx-npm-ui";
import { SignUpFormContext } from "../sign-up-form/sign-up-form.context";
import GartnerBuySmartLogoSVG from "../../../assets/images/gartner-buy-smart-logo.svg";

const ExpiredTokenComponent = () => {
  const { t } = useTranslation();
  const { invitedBy, initTitle } = useContext(SignUpFormContext);

  return (
    <div className={styles.messageContainer}>
      <div className={styles.imgContainer}>
        <img alt={t("Gartner BuySmart logo")} src={GartnerBuySmartLogoSVG} />
      </div>
      <TypographyComponent color="white" styling={"h4"} element="h1">
        {t("Your invitation link to join this evaluation has expired.")}
        {` `}
        {t("Please ask the owner to resend your invitation.")}
      </TypographyComponent>
      <div>
        <div className={styles.divider} />
        {initTitle && (
          <Fragment>
            <TypographyComponent rootClassName={styles.title} styling={"p4"} element={"span"} color="lightBerry">
              {t("Evaluation")}
            </TypographyComponent>
            <TypographyComponent
              rootClassName={styles.titleContent}
              styling={"p1"}
              boldness={"semi"}
              element={"span"}
              color="white"
            >
              {initTitle}
            </TypographyComponent>
          </Fragment>
        )}
        {invitedBy && (
          <Fragment>
            <TypographyComponent rootClassName={styles.title} styling={"p3"} element={"span"} color="lightBerry">
              {t("Owner")}
            </TypographyComponent>
            <TypographyComponent
              rootClassName={styles.titleContent}
              styling={"p1"}
              boldness={"semi"}
              element={"span"}
              color="white"
            >
              {invitedBy}
            </TypographyComponent>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default ExpiredTokenComponent;

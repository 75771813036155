import React, { Fragment } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { TextLink } from "gx-npm-ui";
import ErrorBanner from "../../error-banner/error-banner.component";
import { formHeaderStyles as styles } from "./styles";

const propTypes = { errorMessage: PropTypes.string };
const useStyles = makeStyles(() => styles);
const SignUpFormHeader = ({ errorMessage = "" }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={classes.signUpHeading}>
        <p className={classnames("p1", "semi-bold", classes.title)}>{t("Create a Gartner BuySmart account.")}</p>
        <div className={classes.signUpHeadingLogin}>
          <TextLink href="/login" text={t("Log in")} variant="primary" />
          <p className="p3">{t(" if you already have an account")}</p>
        </div>
        {!!errorMessage && (
          <div className={classes.errorContainer}>
            <ErrorBanner message={errorMessage} />
          </div>
        )}
      </div>
    </Fragment>
  );
};

SignUpFormHeader.propTypes = propTypes;
export default SignUpFormHeader;

import SignUp from "./sign-up.component";
import * as singleSpa from "single-spa";
import MobileMessage from "./components/mobileMessage/mobileMessage";
import OnboardingOverlay from "./ui/onboarding-overlay/onboarding-overlay.component";
import { AppContext } from "./app.context";
import Login from "./components/login/login.component";
import { Fragment, useContext, useEffect, useState } from "react";

const AppV2 = () => {
  const [isSignUp, setIsSignUp] = useState(false);
  useEffect(() => {
    const { pathname } = window.location;
    setIsSignUp(/signup/i.test(pathname));
  }, []);

  const { showOnboardingExperience, redirectUrl, showMobileMessage, setShowMobileMessage } = useContext(AppContext);

  const handleClose = () => {
    if (showOnboardingExperience) {
      setShowMobileMessage(false);
      return;
    }
    singleSpa.navigateToUrl(redirectUrl);
  };

  return (
    <Fragment>
      {isSignUp && !showMobileMessage && <SignUp />}
      {!isSignUp && !showMobileMessage && !showOnboardingExperience && <Login />}
      {showMobileMessage && <MobileMessage onClose={handleClose} />}
      {!showMobileMessage && <OnboardingOverlay isOpen={showOnboardingExperience} />}
    </Fragment>
  );
};

export default AppV2;

import { publish } from "gx-npm-messenger-util";
import { TypographyComponent, useFeatureFlag } from "gx-npm-ui";
import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LoginButton from "../login-button/login-button.component";
import styles from "./first-time-login.module.scss";
import { GCOM_3695_ctaButtonColorUpdate } from "../../../../lib/feature-flags";
import classNames from "classnames";
import { useUserState } from "gx-npm-lib";
import { AppContext } from "../../../../app.context";
import * as reactDeviceDetect from "react-device-detect";
import { navigateToUrl } from "single-spa";
import { LoginFormContext } from "../login-form.context";
import { commonTheme } from "gx-npm-common-styles";

const FirstTimeLogin = () => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const { t } = useTranslation();
  const { firstName, lastName, isOnboarded } = useUserState();
  const { setShowMobileMessage, redirectUrl, setShowOnboardingExperience } = useContext(AppContext);
  const [welcomeStyling, setWelcomeStyling] = useState<"h2" | "p2">("h2");
  const [subWelcomeStyling, setSubWelcomeStyling] = useState<"h5" | "p2">("h5");
  const { setShowSso } = useContext(LoginFormContext);
  const onClickButtonSwitchAcct = () => {
    setShowSso(false);
    publish("CLEAR_USER_STATE");
    publish("WS_DISCONNECT");
  };

  const handleSubmit = () => {
    if (reactDeviceDetect.isMobile) {
      setShowMobileMessage(true);
      return;
    } else {
      if (!isOnboarded) {
        setShowOnboardingExperience(true);
      } else {
        navigateToUrl(redirectUrl);
      }
    }
  };
  const handleResize = useCallback(() => {
    const { md } = commonTheme.breakpoints.values;
    const width = window.innerWidth;

    if (width < md) {
      setSubWelcomeStyling("p2");
      setWelcomeStyling("p2");
    } else if (width >= md) {
      setSubWelcomeStyling("h5");
      setWelcomeStyling("h2");
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <div className={styles.firstTimeContainer}>
      <TypographyComponent styling={welcomeStyling} boldness="medium" element="h2">
        {t("Welcome")}
        {firstName && lastName && (
          <Fragment>
            {`, `}
            <br />
            <TypographyComponent styling={welcomeStyling} boldness="medium" color="yam" element="span">
              {`${firstName} `}
              {`${lastName}!`}
            </TypographyComponent>
          </Fragment>
        )}
      </TypographyComponent>
      <TypographyComponent
        styling={subWelcomeStyling}
        boldness="medium"
        color="coal"
        element="h5"
        rootClassName={styles.secondHeading}
      >
        {t("As a Gartner client, you have instant access to BuySmart. Let's get you set up.")}
      </TypographyComponent>
      <div className={styles.divider} />
      <div>
        <TypographyComponent boldness="medium" color="carbon" rootClassName={styles.notName} element="p">
          {`${t("Not")} ${firstName} ${lastName}? `}
        </TypographyComponent>
        <button
          className={classNames(styles.switchAccounts, isFFGCOM3695 && styles.switchAccountsGCOM3695)}
          onClick={onClickButtonSwitchAcct}
        >
          <TypographyComponent
            boldness="medium"
            color={isFFGCOM3695 ? "defaultCta" : "blueBerry"}
            element="span"
            styling="p3"
          >
            {t("Switch accounts")}
          </TypographyComponent>
        </button>
      </div>
      <LoginButton onSubmit={handleSubmit} />
    </div>
  );
};
export default FirstTimeLogin;

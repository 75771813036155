import { IconButton, InputAdornment } from "@material-ui/core";
import classNames from "classnames";
import { EyeIcon, EyeSlashIcon } from "gx-npm-icons";
import { TextField } from "gx-npm-ui";
import { KeyboardEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { LoginFormContext } from "../login-form.context";
import styles from "./login-form-fields.module.scss";

const LoginFormFields = ({ onSubmit }: { onSubmit: () => void }) => {
  const { t } = useTranslation();
  const { email, password, showPassword, setEmail, setPassword, setShowPassword } = useContext(LoginFormContext);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={styles.loginFields}>
      <TextField
        inputProps={{ autoComplete: "username" }}
        label={t("Email")}
        onChange={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={t("Email")}
        rootClassName={classNames("gx-dragon-username-input", styles.txtEmail)}
        value={email}
      />
      <TextField
        inputProps={{ autoComplete: "current-password" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton data-testid="show-password" edge="end" onClick={handleClickShowPassword}>
                {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={t("Password")}
        onChange={(e) => setPassword(e.target.value)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={t("Password")}
        rootClassName={classNames("gx-dragon-password-input", styles.txtPassword)}
        type={showPassword ? "text" : "password"}
        value={password}
      />
    </div>
  );
};
export default LoginFormFields;

import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./bad-token.styles.module.scss";
import { TypographyComponent } from "gx-npm-ui";
import GartnerBuySmartLogoSVG from "../../../assets/images/gartner-buy-smart-logo.svg";

const BadTokenComponent = () => {
  const { t } = useTranslation();

  return (
    <div className={classNames(styles.messageContainer)}>
      <div className={classNames(styles.leftGrid)}>
        <div className={styles.imgContainer}>
          <img alt={t("Gartner BuySmart logo")} src={GartnerBuySmartLogoSVG} />
        </div>
        <div className={classNames(styles.badToken)}>
          <TypographyComponent styling="h5" color="white" element={"h1"}>
            {t("We were not able to process your request.")}
            {` `}
            {t("If you need help accessing the platform, please contact")}
            {` `}
            <a className={classNames(styles.mailToLink)} href="mailto:buysmart@gartner.com">
              {t("BuySmart@gartner.com")}
            </a>
          </TypographyComponent>
        </div>
      </div>
    </div>
  );
};

export default BadTokenComponent;

const logoStyles = {
  logo: {
    display: "flex",
    gap: "12px",
    "& $buySmartLogo": {
      marginTop: "3px",
    },
  },
  buySmartLogo: {},
};

export { logoStyles };

import { colorPalette, weightPalette } from "gx-npm-common-styles";

const leftDetailClasses = {
  description: {
    transition: "all 0.5s",
    marginTop: "72px",
    "@media (max-width: 1439px)": {
      fontSize: "36px",
      letterSpacing: "0.15px",
      lineHeight: "43.2px",
    },
    "@media (max-width: 413px)": {
      fontSize: "24px",
      letterSpacing: "0.15px",
      lineHeight: "31.2px",
      marginTop: "36px",
    },
  },
  tangerine: {
    color: colorPalette.brand.tangerine.hex,
  },
  brTag: {
    "@media (max-width: 413px)": {
      display: "none",
    },
  },
  divider: {
    backgroundColor: colorPalette.brand.spice.hex,
    height: "4px",
    margin: "32px 0",
    width: "31px",
  },
  signupTitleLabel: {
    color: colorPalette.interactions.lightBerry.hex,
    marginBottom: "8px",
  },
  signupTitle: {
    "@media (max-width: 413px)": {
      "&.p1": {
        fontSize: "16px",
        fontVariationSettings: weightPalette?.semiBold?.variation,
        fontWeight: weightPalette?.semiBold?.amount,
        letterSpacing: "0.25px",
        lineHeight: "24px",
      },
    },
  },
};

export { leftDetailClasses };

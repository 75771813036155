import { makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { publish } from "gx-npm-messenger-util";
import PropType from "prop-types";
import qs from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { postRequest } from "../../../lib/apiRequests";
import SignUpButton from "./signUpButton";
import SignUpFormFields from "./signUpFormFields";
import SignUpFormHeader from "./signUpFormHeader";
import SignUpFormOptions from "./signUpFormOptions";
import { signUpFormClasses as styles } from "./styles";
import { isMobile } from "react-device-detect";
import { AppContext } from "../../../app.context";

const propTypes = {
  email: PropType.string,
  initId: PropType.string,
  token: PropType.string,
  surveyId: PropType.string,
};
const useStyles = makeStyles(() => styles);
const SignUpForm = ({ email = "", initId = "", token = "", surveyId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [errorText, setErrorText] = useState("");
  const [firstName, setFirstName] = useState("");
  const [isComplete, setIsComplete] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [isValidPwd, setIsValidPwd] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [termsPolicyAgreement, setTermsPolicyAgreement] = useState(false);
  const [isBreachedPassword, setIsBreachedPassword] = useState(false);
  const urlRegisterV3 = "api/v3/auth/register";
  const { setShowMobileMessage, setSignUpUrl, setShowOnboardingExperience, setRedirectUrl } = useContext(AppContext);
  useEffect(() => {
    if (firstName && lastName && isValidPwd && termsPolicyAgreement) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [firstName, lastName, isValidPwd, termsPolicyAgreement]);

  const handleFormCompleteV2 = (url) => {
    if (isMobile) {
      setShowMobileMessage(true);
      setRedirectUrl(url);
    }
    setSignUpUrl(url);
    setShowOnboardingExperience(true);
  };
  async function postRegister() {
    const load = JSON.stringify({
      email,
      firstName,
      lastName,
      password,
      termsPolicyAgreement,
      token,
    });
    setIsProcessing(true);
    const response = await postRequest(urlRegisterV3, load);
    if ([200, 201].includes(response.status)) {
      let urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
      let redirectUrl;
      Object.keys(urlParams).forEach((k) => {
        if (["token"].includes(k)) {
          delete urlParams[k];
        }
      });
      if (Object.keys(urlParams).includes("redirect")) {
        const redirectParts = decodeURIComponent(urlParams["redirect"] || "").split("?");
        delete urlParams["redirect"];
        redirectUrl = redirectParts[0];
        if (redirectParts.length > 1) {
          urlParams = { ...urlParams, ...qs.parse(redirectParts[1]) };
        }
      } else {
        redirectUrl = "/s/home";
        if (surveyId) {
          redirectUrl = "/s/tasks-dashboard";
        } else if (initId) {
          redirectUrl = `/s/evaluation/${initId}/overview`;
        }
      }
      if (urlParams && Object.keys(urlParams).length > 0) {
        redirectUrl += `?${qs.stringify(urlParams)}`;
      }
      publish("WS_CONNECT");
      publish("SESSION_EVENT_LOAD");
      handleFormCompleteV2(redirectUrl);
    } else if (
      response.status === 422 &&
      response.data?.systemMessage?.message === "PASSWORD_REQUIREMENTS_UNSATISFIED"
    ) {
      setIsBreachedPassword(true);
    } else {
      setErrorText(t("Oops... something went wrong. Please try again."));
    }
    setIsProcessing(false);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event, inputType) => {
    const targetValue = event.target.value;
    if (inputType === "password") {
      setPassword(targetValue);
    } else if (inputType === "firstName") {
      setFirstName(targetValue);
    } else if (inputType === "lastName") {
      setLastName(targetValue);
    }
  };

  const handleKeyDown = (event, inputType) => {
    const validTypes = ["password"];
    if (validTypes.indexOf(inputType) > -1 && event?.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = (event) => {
    event?.preventDefault();

    if (!firstName || !lastName || !password) {
      setErrorText(t("Please fill out all fields."));
    } else {
      setErrorText("");
      postRegister();
    }
  };

  return (
    <div className={classnames(classes.signUpForm)}>
      <SignUpFormHeader errorMessage={errorText} />
      <form noValidate autoComplete="off">
        <SignUpFormFields
          email={email}
          firstName={firstName}
          lastName={lastName}
          onChange={handleChange}
          onClickShowPassword={handleClickShowPassword}
          onKeyDown={handleKeyDown}
          password={password}
          showPassword={showPassword}
          setIsValidPwd={setIsValidPwd}
          isBreachedPassword={isBreachedPassword}
        />
        <SignUpFormOptions termsPolicyAgreement={termsPolicyAgreement} onChangeAgree={setTermsPolicyAgreement} />
        <SignUpButton disabled={!isComplete} isProcessing={isProcessing} onClick={handleSubmit} />
      </form>
    </div>
  );
};

SignUpForm.propTypes = propTypes;
export default SignUpForm;

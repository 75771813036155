import React from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Checkbox } from "gx-npm-ui";
import { loginFormOptionsStyles as styles } from "./styles";

const propTypes = {
  isRemembering: PropType.bool,
  onChangeRemember: PropType.func,
  urlRecoverLink: PropType.string,
};
const useStyles = makeStyles(() => styles);
const LoginFormOptions = ({ isRemembering = false, onChangeRemember = (_isChecked) => {}, urlRecoverLink = "" }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classNames(classes.loginOptions)}>
      <Checkbox
        checked={isRemembering}
        inputProps={{ "aria-label": t("Remember me") }}
        label={t("Remember me")}
        name="remember"
        onChange={(event) => onChangeRemember(!!event.target.checked)}
        rootClassName={classes.rememberMe}
      />
      <div className={classNames(classes.forgotPassword)}>
        <p className="p4">
          <a className={classes.resetPasswordLink} aria-label={t("Forgot Password")} href={urlRecoverLink}>
            {`${t("Forgot Password")}?`}
          </a>
        </p>
      </div>
    </div>
  );
};

LoginFormOptions.propTypes = propTypes;
export default LoginFormOptions;

import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { appStyles as styles } from "../../styles";
import GartnerBuySmartLogo from "../logo";

const useStyles = makeStyles(() => styles);
const BadToken = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classNames(classes.container, "gx-login-buysmart")}>
      <div className={classNames(classes.leftGrid)}>
        <div className={classNames(classes.leftContainer)}>
          <GartnerBuySmartLogo />
          <div className={classNames(classes.badToken)}>
            <div data-testid={"message"}>
              {t("We were not able to process your request.")}
              {` `}
              {t("If you need help accessing the platform, please contact")}
              {` `}
              <a className={classNames(classes.mailToLink)} href="mailto:buysmart@gartner.com">
                {t("BuySmart@gartner.com")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BadToken;

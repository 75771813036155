import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ButtonLoader } from "gx-npm-ui";
import styles from "./sign-up-form-button.styles.module.scss";
import { SignUpFormContext } from "../sign-up-form.context";
import { UUID } from "gx-npm-lib";

interface SignupJwtBody {
  email: string;
  initId: UUID;
  initTitle: string;
  invitedBy: string;
  surveyId: UUID;
}

const SignUpButton: React.FC = () => {
  const { t } = useTranslation();
  const {
    isProcessing,
    isCompleteSignUpForm,
    isCompleteProfileForm,
    setIsProcessing,
    page,
    setPage,
    setSurveyId,
    surveyId,
  } = useContext(SignUpFormContext);

  const deconstructToken = (tok: string): Readonly<SignupJwtBody> | null => {
    try {
      const tokenParts = tok.split(".");
      if (tokenParts.length <= 1) {
        return null;
      }
      const decodedString = window.atob(tokenParts[1]);
      return Object.freeze(JSON.parse(decodedString));
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const rawToken = new URLSearchParams(location.search).get("token");
    if (!rawToken) {
      return;
    }
    const tokenObj = deconstructToken(rawToken);
    if (tokenObj) {
      setSurveyId(tokenObj.surveyId);
    }
  }, [setSurveyId]);

  const handleClick = () => {
    setIsProcessing(true);
    // Your click handler logic here
  };

  return (
    <div className={styles.signUpButtonContainer}>
      {page === 1 && !surveyId ? (
        <ButtonLoader
          ariaLabel={t("next step button")}
          btnClass="btn-primary"
          disabled={!isCompleteSignUpForm}
          isLoading={isProcessing}
          onClick={() => setPage(2)}
        >
          {t("Next: Complete Profile")}
        </ButtonLoader>
      ) : (
        <ButtonLoader
          ariaLabel={t("sign up button")}
          btnClass="btn-primary"
          disabled={surveyId ? !isCompleteSignUpForm : !isCompleteProfileForm}
          isLoading={isProcessing}
          onClick={handleClick}
        >
          {t("Sign up")}
        </ButtonLoader>
      )}
    </div>
  );
};

export default SignUpButton;

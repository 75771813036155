import { colorPalette } from "gx-npm-common-styles";
import backgroundSvg from "./assets/images/background.svg";
import backgroundDiagonalSvg from "./assets/images/backgroundDiagonal.svg";

const appStyles = {
  container: {
    backgroundColor: colorPalette.basic.primary.hex,
    backgroundImage: `url("${backgroundSvg}")`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: "right",
    display: "grid",
    gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
    height: "100%",
    minHeight: "100vh",
    minWidth: "1024px",
    "&.gx-login-buysmart": {
      backgroundImage: `url("${backgroundDiagonalSvg}")`,
    },
    "@media (max-width: 413px)": {
      backgroundSize: "170px",
      display: "block",
      gridTemplateColumns: "unset",
      minWidth: "unset",
      paddingBottom: "30px",
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  leftGrid: {
    display: "flex",
    justifyContent: "flex-end",
    gridColumn: "span 6 / span 6",
    "& $leftContainer": {
      marginTop: 38,
      marginRight: 108,
      marginBottom: 38,
      marginLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
      transition: "all 0.5s",
      width: 486,
      "@media (max-width: 1439px)": {
        marginTop: 120,
        marginRight: 35,
        width: 366,
      },
      "@media (min-width: 1440px) and (max-width: 1919px)": {
        marginTop: 180,
      },
      "@media (min-width: 1920px)": {
        marginTop: 220,
      },
      "@media (max-width: 413px)": {
        marginTop: "0px",
        marginBottom: "0px",
        marginRight: "0px",
        paddingTop: "57px",
        width: "330px",
      },
    },
    "& $leftContainerSurveyRecipient": {
      width: 520,
    },
    "@media (max-width: 413px)": {
      display: "unset",
      justifyContent: "unset",
      gridColumn: "unset",
    },
  },
  leftContainer: {},
  leftContainerSurveyRecipient: {},
  rightGrid: {
    gridColumn: "span 6 / span 6",
    "& $rightContainer": {
      backgroundColor: "transparent",
      color: "#000000",
      marginTop: 0,
      marginRight: 0,
      marginBottom: 288,
      marginLeft: 12,
      paddingTop: 0,
      paddingBottom: 0,
      transition: "all 0.5s",
      width: "486px",
      "@media (max-width: 1439px)": {
        marginTop: 120,
        width: 397,
      },
      "@media (min-width: 1440px) and (max-width: 1919px)": {
        marginTop: 180,
      },
      "@media (min-width: 1920px)": {
        marginTop: 220,
      },
      "@media (max-width: 413px)": {
        marginBottom: "0px",
        marginTop: "56px",
        marginLeft: "0px",
        width: "366px",
      },
    },
  },
  rightContainer: {},
  badToken: {
    marginTop: "68px",
    fontSize: "26px",
    lineHeight: "130%",
    fontWeight: "600",
    "@media (max-width: 413px)": {
      marginTop: "51px",
      fontSize: "24px",
      lineHeight: "31.2px",
      letterSpacing: "0.15px",
    },
  },
  errorMsg: {
    marginTop: "68px",
    fontSize: "26px",
    lineHeight: "130%",
    "@media (max-width: 413px)": {
      marginTop: "51px",
      fontSize: "24px",
      lineHeight: "31.2px",
      letterSpacing: "0.15px",
    },
  },
  divider: {
    width: "35px",
    borderStyle: "solid",
    borderTopWidth: "2px",
    borderColor: colorPalette.brand.spice.hex,
    marginTop: "40px",
    marginBottom: "40px",
  },
  errTitle: {
    marginBottom: "12px",
    marginTop: "40px",
    color: colorPalette.interactions.lightBerry.hex,
    "@media (max-width: 413px)": {
      marginBottom: "8px",
      marginTop: "24px",
      "&.p3": {
        fontSize: "12px",
        lineHeight: "18px",
        letterSpacing: "0.35px",
      },
      "&.p1": {
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.25px",
      },
    },
  },
  mailToLink: {
    borderBottom: "2px solid #B4CdF7",
    color: "#B4CDF7",
    paddingBottom: "3px",
    textDecoration: "none",
  },
  "@global": {
    "html body": {
      minWidth: "414px",
    },
  },
};

export { appStyles };

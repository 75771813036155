import classNames from "classnames";
import { KeyboardEvent, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import * as singleSpa from "single-spa";
import { TooltipV2 } from "../tooltip-v2";
import { ProductLogo } from "../productLogo";
import { TypographyComponent } from "../typography/typography.component";
import styles from "./product-link.styles.module.scss";
import { GCOM_3695_ctaButtonColorUpdate } from "../feature-flags";
import { useFeatureFlag } from "../featureFlags";

type ProductLinkProps = {
  href?: string;
  imageLoc?: string;
  logoSize?: "24px" | "32px";
  name?: string;
  onClick?: () => void;
  rootClassName?: string;
  textBoldness?: "regular" | "semi" | "medium";
  textType?: "p1" | "p2" | "p3" | "p4";
};
export const ProductLinkComponent = ({
  href = "",
  imageLoc = "",
  logoSize = "32px",
  name = "",
  onClick = () => {},
  rootClassName = "",
  textBoldness = "semi",
  textType = "p3",
}: ProductLinkProps) => {
  const { t } = useTranslation();
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const handleClick = (event: KeyboardEvent | MouseEvent) => {
    event.preventDefault();
    onClick();
    singleSpa.navigateToUrl(href);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleClick(event);
    }
  };
  return (
    <div>
      <TooltipV2
        enterNextDelay={2000}
        placement="top-start"
        PopperProps={{ modifiers: { offset: { offset: "-66px, 8px" } } }}
        rootClassName={styles.tooltipArrowPosition}
        title={t("Click to view profile")}
      >
        <a
          className={classNames(styles.productLinkTag, rootClassName)}
          href={href}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
        >
          <ProductLogo
            hasAltText={false}
            imageHeight={logoSize}
            imageWidth={logoSize}
            logo={imageLoc}
            name={name}
            rootClassName={styles.productLogoImg}
          />
          <TypographyComponent
            boldness={textBoldness}
            rootClassName={classNames(styles.productNameText, isFFGCOM3695 && styles.productNameTextGCOM3695)}
            type={textType}
          >
            {name}
          </TypographyComponent>
        </a>
      </TooltipV2>
    </div>
  );
};

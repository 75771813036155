import { colorPalette, weightPalette } from "gx-npm-common-styles";

const semiBoldAmount = weightPalette.semiBold.amount;
const semiBoldVariation = weightPalette.semiBold.variation;
const defaultCta = colorPalette.interactions.defaultCta.hex;
const selectedCta = colorPalette.interactions.selectedCta.hex;

const loginFormClasses = {
  loginForm: {
    backgroundColor: colorPalette.basic.white.hex,
    borderRadius: "8px",
    boxShadow: "0px 8px 8px rgba(20, 19, 18, 0.16)",
    padding: "56px 64px 72px 65px",
    "&.first-time": {
      padding: "60px 64px",
      transition: "all 0.5s",
      "@media (max-width: 1439px)": {
        padding: "48px 40px",
        "& h2": {
          fontSize: "28px",
          lineHeight: "36.4px",
        },
        "& h3": {
          fontSize: "24px",
        },
      },
      "@media (max-width: 413px)": {
        padding: "40px 24px",
        "& h2": {
          fontSize: "24px",
          lineHeight: "31.2px",
          letterSpacing: "0.15px",
        },
        "& h3": {
          fontSize: "18px",
          lineHeight: "25.2px",
          letterSpacing: "0.25px",
        },
      },
    },
    "@media (max-width: 1439px)": {
      padding: "56px 42px 48px 40px",
    },
    "@media (max-width: 413px)": {
      padding: "32px 24px 48px 24px",
    },
  },
  loginBtnWrapper: {
    marginTop: 52,
  },
};

const firstTimeLoginStyles = {
  loggedIn: {
    "& h2": {
      fontSize: "36px",
      lineHeight: "43.2px",
      marginTop: 4,
      marginBottom: 32,
      "& .blackBerry": {
        color: selectedCta,
      },
    },
    "& h3": {
      color: colorPalette.neutrals.iron.hex,
    },
    "& $switchAccounts": {
      display: "flex",
      marginTop: 72,
      "& p, & button": {
        fontVariationSettings: semiBoldVariation,
        fontWeight: semiBoldAmount,
      },
      "& button": {
        backgroundColor: "transparent",
        border: 0,
        color: defaultCta,
        cursor: "pointer",
      },
      "@media (max-width: 413px)": {
        marginTop: "64px",
      },
    },
  },

  switchAccounts: {},
  secondHeading: {
    marginBottom: "56px",
  },
};

const loginButtonStyles = {
  loginBtnWrapper: {
    alignItems: "center",
    backgroundColor: "transparent",
    border: 0,
    cursor: "pointer",
    display: "flex !important",
    marginTop: 50,
    paddingLeft: 0,
    "& span": {
      color: defaultCta,
      fontSize: "14px",
      fontVariationSettings: semiBoldVariation,
      fontWeight: semiBoldAmount,
      letterSpacing: "0.25px",
      lineHeight: "21px",
      marginLeft: "25px",
    },
    "&:hover span": {
      textDecoration: "underline",
    },
  },
};

const formHeaderStyles = {
  loginHeading: {
    display: "flex",
    marginBottom: 40,
    "& div:nth-child(-n+2)": {
      float: "left",
      width: "100%",
    },
    "@media (max-width: 413px)": {
      marginBottom: "32px",
      "& h5": {
        fontSize: "14px",
        letterSpacing: "0.25px",
        lineHeight: "21px",
      },
    },
  },
  errorContainer: {
    marginBottom: "24px",
    "@media (max-width: 1439px)": {
      marginBottom: "32px",
    },
  },
};

const loginFormFieldsStyles = {
  txtEmail: {
    marginBottom: "24px",
  },
  txtPassword: {
    marginBottom: "2px",
  },
};

const loginFormOptionsStyles = {
  loginOptions: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  rememberMe: {
    marginLeft: "-1px",
    "@media (max-width: 1439px)": {
      marginTop: "4px",
    },
  },
  forgotPassword: {
    display: "inline-flex",
  },
  resetPasswordLink: {
    color: defaultCta,
  },
};

export {
  firstTimeLoginStyles,
  formHeaderStyles,
  loginButtonStyles,
  loginFormClasses,
  loginFormFieldsStyles,
  loginFormOptionsStyles,
};

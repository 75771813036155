import { commonTheme } from "gx-npm-common-styles";
import { TypographyComponent, TypographyStyling } from "gx-npm-ui";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GartnerBuySmartLogoSVG from "../../../assets/images/gartner-buy-smart-logo.svg";
import styles from "./welcome-message.styles.module.scss";
import { UUID } from "gx-npm-lib";
import { useLocation } from "react-router-dom";

interface SignupJwtBody {
  email: string;
  initId: UUID;
  initTitle: string;
  invitedBy: string;
  surveyId: UUID;
}

const WelcomeMessageComponent = () => {
  const { t } = useTranslation();
  const [messageStyling, setMessageStyling] = useState<TypographyStyling>("h4");
  const [initTitle, setInitTitle] = useState<string>("");
  const [invitedBy, setInvitedBy] = useState<string>("");
  const [surveyId, setSurveyId] = useState<UUID>("");
  const location = useLocation();

  const deconstructToken = (tok: string): Readonly<SignupJwtBody> | null => {
    try {
      const tokenParts = tok.split(".");
      if (tokenParts.length <= 1) {
        return null;
      }
      const decodedString = window.atob(tokenParts[1]);
      return Object.freeze(JSON.parse(decodedString));
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const rawToken = new URLSearchParams(location.search).get("token");
    if (!rawToken) {
      return;
    }
    const tokenObj = deconstructToken(rawToken);
    if (tokenObj) {
      setInitTitle(tokenObj.initTitle);
      setInvitedBy(tokenObj.invitedBy);
      setSurveyId(tokenObj.surveyId);
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= commonTheme.breakpoints.values.xl) {
        setMessageStyling("h1");
      } else if (width >= commonTheme.breakpoints.values.md) {
        setMessageStyling("h2");
      } else {
        setMessageStyling("h4");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.messageContainer}>
      <div className={styles.imgContainer}>
        <img alt={t("Gartner BuySmart logo")} src={GartnerBuySmartLogoSVG} />
      </div>
      {!surveyId ? (
        <Fragment>
          <TypographyComponent boldness="medium" color="white" element="h1" styling={messageStyling}>
            <TypographyComponent color="tangerine" boldness="medium" element="span">
              {invitedBy}
            </TypographyComponent>
            {` `}
            {t("has invited you to an evaluation on Gartner BuySmart!")}
            <br />
            <TypographyComponent styling="p4" element="span" color="lightBerry">
              {t("Evaluation")}{" "}
            </TypographyComponent>
          </TypographyComponent>
          <TypographyComponent styling="p1" element="span" color="white">
            {initTitle}{" "}
          </TypographyComponent>
        </Fragment>
      ) : (
        <Fragment>
          <TypographyComponent boldness="medium" color="white" element="h1" styling={messageStyling}>
            {t("Join hundreds of other vendors")}
            {` `}
            <br />
            <TypographyComponent color="tangerine" boldness="medium" element="span">
              {t("engaging with prospects")}
            </TypographyComponent>
            {` `}
            {t("on BuySmart.")}
          </TypographyComponent>
        </Fragment>
      )}
    </div>
  );
};

export default WelcomeMessageComponent;

import React, { Fragment, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { CheckmarkSmallIcon, EyeIcon, EyeSlashIcon } from "gx-npm-icons";
import { TextField, TypographyComponent } from "gx-npm-ui";
import styles from "./validate-password.module.scss";
import { SignUpFormContext } from "../sign-up-form.context";

const ValidatePassword = () => {
  const { t } = useTranslation();
  const {
    password,
    setPassword,
    showPassword,
    setShowPassword,
    setIsValidPwd,
    isBreachedPassword,
    confirmPassword,
    setConfirmPassword,
  } = useContext(SignUpFormContext);

  const [isBetween8And40, setIsBetween8And40] = useState(false);
  const [hasOneLetter, setHasOneLetter] = useState(false);
  const [hasOneSpChar, setHasOneSpChar] = useState(false);
  const [_passConfirmed, setPassConfirmed] = useState(false);
  const [isPwdOrGartner, setIsPwdOrGartner] = useState(false);
  const passColor = colorPalette.status.darkPear.hex;
  const failColor = colorPalette.neutrals.stone.hex;

  useEffect(() => {
    setIsBetween8And40(password.length >= 8 && password.length <= 40);
    setHasOneLetter(containsAnyLetter(password));
    setHasOneSpChar(containsAnySpecCharOrNum(password));
    setPassConfirmed(password === confirmPassword && password !== "");
    setIsPwdOrGartner(containsGartnerOrPassword(password));

    setIsValidPwd(
      password.length >= 8 &&
        password.length <= 40 &&
        containsAnyLetter(password) &&
        containsAnySpecCharOrNum(password) &&
        password === confirmPassword &&
        !containsGartnerOrPassword(password)
    );
  }, [password, setIsValidPwd, confirmPassword]);

  const containsAnyLetter = (str: string) => {
    return /[a-zA-Z]/.test(str);
  };

  const containsAnySpecCharOrNum = (str: string) => {
    const specialChars = /[`\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    return specialChars.test(str);
  };

  const containsGartnerOrPassword = (str: string) => {
    const gartnerOrPassword = /gartner|password/i;
    return gartnerOrPassword.test(str);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, inputType: string) => {
    const targetValue = event.target.value;
    if (inputType === "password") {
      setPassword(targetValue);
    }
  };

  return (
    <Fragment>
      <TextField
        inputProps={{ autoComplete: "current-password" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={handleClickShowPassword}>
                {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={t("Password *")}
        onChange={(event) => handleChange(event, "password")}
        placeholder={t("Password *")}
        rootClassName={isBreachedPassword ? styles.txtPasswordError : styles.txtPassword}
        type={showPassword ? "text" : "password"}
        value={password || ""}
      />
      <TypographyComponent rootClassName={classNames(styles.pwdValidateItem, styles.topClass)} styling="p4">
        <CheckmarkSmallIcon fillPath={!isPwdOrGartner ? passColor : failColor} />
        <TypographyComponent
          rootClassName={classNames({ pwdIsNotValid: isPwdOrGartner }, styles.pwdValidateParam)}
          styling="span"
        >
          {t("Cannot contain ‘password’ or ‘gartner’")}
        </TypographyComponent>
      </TypographyComponent>
      <TypographyComponent rootClassName={styles.pwdValidateItem} styling="p4">
        <CheckmarkSmallIcon fillPath={isBetween8And40 ? passColor : failColor} />
        <TypographyComponent
          rootClassName={classNames({ pwdIsNotValid: !isBetween8And40 }, styles.pwdValidateParam)}
          styling="span"
        >
          {t("Between 8 and 40 characters")}
        </TypographyComponent>
      </TypographyComponent>
      <TypographyComponent rootClassName={styles.pwdValidateItem} styling="p4">
        <CheckmarkSmallIcon fillPath={hasOneLetter ? passColor : failColor} />
        <TypographyComponent
          rootClassName={classNames({ pwdIsNotValid: !hasOneLetter }, styles.pwdValidateParam)}
          styling="span"
        >
          {t("At least one letter")}
        </TypographyComponent>
      </TypographyComponent>
      <TypographyComponent rootClassName={classNames(styles.pwdValidateItem, styles.bottomClass)} styling="p4">
        <CheckmarkSmallIcon fillPath={hasOneSpChar ? passColor : failColor} />
        <TypographyComponent
          rootClassName={classNames({ pwdIsNotValid: !hasOneSpChar }, styles.pwdValidateParam)}
          styling="span"
        >
          {t("At least one number or special character")}
        </TypographyComponent>
      </TypographyComponent>
      <TextField
        inputProps={{ autoComplete: "current-password" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={handleClickShowPassword}>
                {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={t("Confirm Password *")}
        onChange={(event) => setConfirmPassword(event.target.value)}
        placeholder={t("Confirm Password *")}
        rootClassName={isBreachedPassword ? styles.txtPasswordError : styles.txtPassword}
        type={showPassword ? "text" : "password"}
        value={confirmPassword || ""}
      />
    </Fragment>
  );
};

export default ValidatePassword;

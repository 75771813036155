import { Drawer } from "@material-ui/core";
import classNames from "classnames";
import { CloseIcon } from "gx-npm-icons";
import { Button, IconButton, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import styles from "./mobile-warning-overlay.styles.module.scss";

type MobileWarningOverlayProps = { isOpen: boolean; onClose?: () => void };
const MobileWarningOverlayComponent = ({ isOpen = false, onClose = () => {} }: MobileWarningOverlayProps) => {
  const { t } = useTranslation();
  const drawerClasses = { paper: styles.drawerPaper };

  return (
    <Drawer classes={drawerClasses} open={isOpen} transitionDuration={0}>
      <div className={classNames(styles.closeButtonWrapper)}>
        <IconButton ariaLabel={t("Close")} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <section className={styles.messageContainer}>
        <TypographyComponent color="tangerine" element="h2" styling="h1">
          {t("Hold Up.")}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.text} color="gartnerBlue" element="h3" styling="h4">
          {t("We see you're on a mobile device - BuySmart is designed to be optimally experienced on larger screens.")}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.subText} color="iron" styling="p1">
          {t(
            "Please understand that you may experience reduced or impaired functionality by continuing on your mobile device."
          )}
        </TypographyComponent>
        <Button onClick={onClose} rootClassName="btn-primary">
          {t("I Understand")}
        </Button>
      </section>
    </Drawer>
  );
};

export default MobileWarningOverlayComponent;

import React from "react";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Checkbox, TextLink } from "gx-npm-ui";
import { signUpFormOptionsStyles as styles } from "./styles";

const propTypes = {
  termsPolicyAgreement: PropType.bool,
  onChangeAgree: PropType.func,
};
const useStyles = makeStyles(() => styles);
const SignUpFormOptions = ({ termsPolicyAgreement = false, onChangeAgree = (_isAgreed) => {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Checkbox
      checked={termsPolicyAgreement}
      inputProps={{ "aria-label": t("Agree Terms") }}
      label={
        <div>
          {`${t("I agree to Gartner")} `}
          <TextLink href="https://www.gartner.com/en/about/policies/terms-of-use" target="_blank" text={t("Terms")} />
          {` ${t("and")} `}
          <TextLink
            href="https://www.gartner.com/en/about/policies/privacy"
            target="_blank"
            text={t("Privacy Policy.")}
          />
        </div>
      }
      name="agree"
      onChange={(event) => onChangeAgree(!!event.target.checked)}
      rootClassName={classes.agreeTerms}
    />
  );
};

SignUpFormOptions.propTypes = propTypes;
export default SignUpFormOptions;

import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import ErrorBanner from "../error-banner/error-banner.component";
import { formHeaderStyles as styles } from "./styles";

const propTypes = { errorMessage: PropTypes.string };
const useStyles = makeStyles(() => styles);
const FormHeader = ({ errorMessage = "" }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.loginHeading}>
        <div>
          <h5>{t("Access Gartner BuySmart using your existing Gartner.com username and password.")}</h5>
        </div>
      </div>
      {!!errorMessage && (
        <div className={classes.errorContainer}>
          <ErrorBanner message={errorMessage} />
        </div>
      )}
    </Fragment>
  );
};

FormHeader.propTypes = propTypes;
export default FormHeader;

import { makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { useUserState } from "gx-npm-lib";
import { publish } from "gx-npm-messenger-util";
import React, { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import domains from "../../assets/domains.json";
import { constructValidRedirect, environmentFromWindowLocation } from "../../lib";
import { getRequest, postRequest } from "../../lib/apiRequests";
import FirstTimeLogin from "./first-time-login.component";
import FormHeader from "./formHeader";
import LoginButton from "./loginButton";
import LoginFormFields from "./loginFormFields";
import LoginFormOptions from "./loginFormOptions";
import { loginFormClasses as styles } from "./styles";
import qs from "qs";
import * as reactDeviceDetect from "react-device-detect";
import * as singleSpa from "single-spa";
import { AppContext } from "../../app.context";
import { OnboardingUser } from "../../app.constants";

const env = environmentFromWindowLocation();
const gcomDomain = domains?.[env]?.gcom;
const urlHashProfile = "#/profile";
const urlLinkRecover = "account/recover";
const urlPageHome = "/s/home";
const urlPageTasksDashboard = "/s/tasks-dashboard";

const useStyles = makeStyles(() => styles);
const LoginForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isGcomAuth, setIsGcomAuth] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [isQueryStringLoaded, setIsQueryStringLoaded] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);
  const [nonGxUser, setNonGxUser] = useState(null);
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);
  const [urlPageRecovery, setUrlPageRecovery] = useState("");
  const [urlPageRedirect, setUrlPageRedirect] = useState("");
  const [user, setUser] = useState(null);
  const userState = useUserState();
  const [showPassword, setShowPassword] = useState(false);
  const [persistedUrlParams, setPersistedUrlParams] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const urlAuthCookieCheck = "api/v2/auth/login/check-cookie";
  const urlAuthCookieSwap = "api/v2/auth/login/tmp-swap";
  const urlAuthInitV3 = "api/v3/auth/login/init";
  const urlAuthLoginV3 = "api/v3/auth/login";
  const urlClearTmpCookie = "api/v2/auth/login/clear-tmp";
  const { setShowMobileMessage, setShowOnboardingExperience, setOnboardingUser, setRedirectUrl } =
    useContext(AppContext);

  useEffect(() => {
    const { hash, search } = window.location;
    const urlParams = qs.parse(search, { ignoreQueryPrefix: true }) || {};
    const redirect = urlParams?.redirect || "";
    const uriHash = redirect && hash ? hash : "";
    setIsLogout(!!urlParams?.logout);
    setUrlPageRecovery(gcomDomain ? `${gcomDomain}/${urlLinkRecover}` : "#");
    setUrlPageRedirect(redirect + uriHash);
    setPersistedUrlParams(urlParams);
    setIsQueryStringLoaded(true);
  }, []);
  const showFirstTimeUser = isGcomAuth && !!user?.profileComplete === false;
  const stringifyPersistedUrlParams = useCallback(() => {
    if (persistedUrlParams && Object.keys(persistedUrlParams).length > 0) {
      return `?${qs.stringify(persistedUrlParams)}`;
    }
    return "";
  }, [persistedUrlParams]);

  useEffect(() => {
    if (!user) {
      return;
    }
    let url = "";
    if (user.profileComplete) {
      url = constructValidRedirect(urlPageRedirect) || `${urlPageHome}${stringifyPersistedUrlParams()}`;
    } else if (user.hasTasks && !user.hasInitiatives && !user.isEntitled) {
      url = urlPageTasksDashboard;
    } else if (user.userId) {
      navProfile();
      return;
    }
    if (!url) {
      return;
    }
    publish("WS_CONNECT");
    const isSurveyRecipient = user?.hasTasks && !user.hasInitiatives && !user.isEntitled;
    handleFormComplete(url, showFirstTimeUser && !isSurveyRecipient);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, showFirstTimeUser]);
  useEffect(() => {
    if (isQueryStringLoaded && !isLogout) {
      getIsValidCookie();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogout, isQueryStringLoaded]);
  useEffect(() => {
    if (userState.hasError) {
      setErrorText(t("Oops... something went wrong. Please try again."));
    } else if (userState.hasUserState) {
      setUser(userState);
    }
  }, [userState, t]);
  const handleFormComplete = (url, showOnboardingExperience) => {
    if (reactDeviceDetect.isMobile) {
      setShowMobileMessage(true);
      setRedirectUrl(url);
    } else if (!showOnboardingExperience) {
      singleSpa.navigateToUrl(url);
    }
    if (showOnboardingExperience) {
      setOnboardingUser(OnboardingUser.EVALUATOR);
      setRedirectUrl(url);
      setShowOnboardingExperience(true);
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e, inputType) => {
    if (inputType === "email") {
      setEmail(e.target.value);
    } else if (inputType === "password") {
      setPassword(e.target.value);
    }
  };

  const handleKeyDown = (event, inputType) => {
    const validTypes = ["email", "password"];
    if (validTypes.indexOf(inputType) > -1 && event?.key === "Enter") {
      handleSubmit(event);
    }
  };

  const handleSubmit = (event) => {
    event?.preventDefault();

    if (!email || !password) {
      setErrorText(t("Please fill out all fields."));
    } else {
      setErrorText("");
      postAuthCredentials();
    }
  };

  const handleNav = () => {
    swapCookies();
  };

  const navProfile = () => {
    let url = urlPageHome;
    if (persistedUrlParams && Object.keys(persistedUrlParams).length > 0) {
      url += `?${qs.stringify(persistedUrlParams)}`;
    }
    const redirectUri = constructValidRedirect(urlPageRedirect);
    if (redirectUri) {
      url = `${redirectUri}${urlHashProfile}`;
    }
    publish("WS_CONNECT");
    if (isGcomAuth) {
      handleLoginEvents();
    }
    const isSurveyRecipient = user?.hasTasks && !user.hasInitiatives && !user.isEntitled;
    handleFormComplete(url, !!user?.profileComplete === false && !isSurveyRecipient);
  };

  const handleSwitchAccounts = () => {
    setNonGxUser(null);
    clearTmpCookies();
  };

  const handleLoginEvents = () => {
    publish("USER_STATE_LOAD");
    publish("SESSION_EVENT_LOAD");
  };

  const appendParams = (url) => {
    if (persistedUrlParams?.gxtm_source && persistedUrlParams?.gxtm_page) {
      url += `?gxtm_source=${persistedUrlParams?.gxtm_source}&gxtm_page=${persistedUrlParams?.gxtm_page}`;
    } else if (persistedUrlParams?.gxtm_source) {
      url += `?gxtm_source=${persistedUrlParams?.gxtm_source}`;
    } else if (urlPageRedirect) {
      const redirectUrl = decodeURI(urlPageRedirect);
      const queryString = redirectUrl.split("?")[1];
      const params = new URLSearchParams(queryString);
      const gxtm_source = params.get("gxtm_source");
      const gxtm_page = params.get("gxtm_page");
      if (gxtm_source && gxtm_page) {
        url += `?gxtm_source=${gxtm_source}&gxtm_page=${gxtm_page}`;
      } else if (gxtm_source) {
        url += `?gxtm_source=${gxtm_source}`;
      }
    }
    return url;
  };
  async function postAuthCredentials() {
    const config = { withCredentials: true };
    const load = JSON.stringify({ username: email, password, remember });
    let url = urlAuthLoginV3;
    url = appendParams(url);
    setIsLoading(true);

    const response = await postRequest(url, load, config);

    setIsLoading(false);

    if ([200, 201].includes(response.status)) {
      handleLoginEvents();
    } else if ([400, 401, 422].indexOf(response.status) > -1) {
      setErrorText(t("The email or password is not correct."));
    } else if (response.status === 403 && response.data?.systemMessage?.message === "NOT_ENTITLED") {
      const error = t("This account does not have permission to access Gartner BuySmart.");
      const advice = t("Please contact your Gartner Account Manager for assistance.");
      setErrorText(`${error} ${advice}`);
    } else if (response.status === 403 && response.data?.message === "FREE_TRIAL_EXPIRED") {
      setErrorText(t("Your free trial period has expired."));
    } else {
      setErrorText("Oops... something went wrong. Please try again.");
    }
  }

  async function swapCookies(profileComplete = false) {
    const response = await getRequest(urlAuthCookieSwap);
    if (response.status === 200) {
      if (profileComplete) {
        handleLoginEvents();
      } else {
        navProfile();
      }
    } else {
      setErrorText(t("Oops... something went wrong. Please try again."));
    }
  }

  async function clearTmpCookies() {
    const response = await getRequest(urlClearTmpCookie);
    if (response.status === 200) {
      setIsGcomAuth(false);
      setUser(null);
    } else {
      setErrorText(t("Oops... something went wrong. Please try again."));
    }
  }

  async function getIsValidCookie() {
    if (!isValidToken) {
      let url = urlAuthInitV3;
      url = appendParams(url);

      const response = await getRequest(url);
      if (response.status === 200 && response.data?.data) {
        setIsValidToken(true);
        if (response.data.data.fromGcom) {
          getCheckGxCookie();
        } else {
          handleLoginEvents();
        }
      }
    }
  }

  async function getCheckGxCookie() {
    const response = await getRequest(urlAuthCookieCheck);
    if (response.status === 200 && response.data?.data) {
      const { data } = response.data;
      if (data.profileComplete) {
        swapCookies(true);
      } else {
        setNonGxUser(data);
        setIsGcomAuth(true);
      }
    } else {
      setErrorText(t("Oops... something went wrong. Please try again."));
    }
  }

  return (
    <div className={classnames(classes.loginForm, showFirstTimeUser && "first-time")}>
      {showFirstTimeUser && (
        <FirstTimeLogin
          firstName={nonGxUser?.firstName || ""}
          lastName={nonGxUser?.lastName || ""}
          onClickButtonLogin={handleNav}
          onClickButtonSwitchAcct={handleSwitchAccounts}
        />
      )}
      {!showFirstTimeUser && (
        <Fragment>
          <FormHeader errorMessage={errorText} />
          <form noValidate autoComplete="off">
            <LoginFormFields
              email={email}
              onChange={handleChange}
              onClickShowPassword={handleClickShowPassword}
              onKeyDown={handleKeyDown}
              password={password}
              showPassword={showPassword}
            />
            <LoginFormOptions
              isRemembering={remember}
              onChangeRemember={setRemember}
              urlRecoverLink={urlPageRecovery}
            />
            <div className={classes.loginBtnWrapper}>
              <LoginButton onClick={handleSubmit} isLoading={isLoading} />
            </div>
          </form>
        </Fragment>
      )}
    </div>
  );
};
export default LoginForm;

import React, { Fragment } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { leftDetailClasses as styles } from "../../leftDetail/styles";
import GartnerBuySmartLogo from "../../logo";

const propTypes = { initTitle: PropType.string, invitedBy: PropType.string };
const useStyles = makeStyles(() => styles);
const SignUpLeftDetail = ({ initTitle = "", invitedBy = "" }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <GartnerBuySmartLogo />
      {invitedBy && (
        <h1 className={classes.description}>
          <span className={classes.tangerine}>{invitedBy}</span>
          {` `}
          {t("has invited you to an evaluation on Gartner BuySmart!")}
        </h1>
      )}
      {initTitle && (
        <Fragment>
          <div className={classes.divider} />
          <p className={classNames(classes.signupTitleLabel, "p4")}>{t("Initiative")}</p>
          <p className={classNames(classes.signupTitle, "p1")}>{initTitle}</p>
        </Fragment>
      )}
    </Fragment>
  );
};

SignUpLeftDetail.propTypes = propTypes;
export default SignUpLeftDetail;

import React, { Fragment } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { makeStyles, IconButton, InputAdornment } from "@material-ui/core";
import { handleEvent } from "gx-npm-lib";
import { TextField } from "gx-npm-ui";
import { EyeIcon, EyeSlashIcon } from "gx-npm-icons";
import { loginFormFieldsStyles as styles } from "./styles";
const propTypes = {
  email: PropType.string,
  onChange: PropType.func,
  onClickShowPassword: PropType.func,
  onKeyDown: PropType.func,
  password: PropType.string,
  showPassword: PropType.bool,
};
const useStyles = makeStyles(() => styles);
const LoginFormFields = ({
  email = "",
  onChange = null,
  onClickShowPassword = null,
  onKeyDown = null,
  password = "",
  showPassword = false,
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <TextField
        autoFocus={true}
        inputProps={{ autoComplete: "username" }}
        label="Email"
        onChange={(e) => handleEvent(onChange, e, "email")}
        onKeyDown={(e) => handleEvent(onKeyDown, e, "email")}
        placeholder="Email"
        rootClassName={classNames("gx-dragon-username-input", classes.txtEmail)}
        value={email}
      />
      <TextField
        inputProps={{ autoComplete: "current-password" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={() => handleEvent(onClickShowPassword)}>
                {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label="Password"
        onChange={(e) => handleEvent(onChange, e, "password")}
        onKeyDown={(e) => handleEvent(onKeyDown, e, "password")}
        placeholder="Password"
        rootClassName={classNames("gx-dragon-password-input", classes.txtPassword)}
        type={showPassword ? "text" : "password"}
        value={password}
      />
    </Fragment>
  );
};

LoginFormFields.propTypes = propTypes;
export default LoginFormFields;

import { colorPalette, weightPalette } from "gx-npm-common-styles";

const errorStyles = {
  bnrContainer: {
    alignItems: "center",
    backgroundColor: colorPalette.status.lightCherry.hex,
    display: "flex",
    height: "100%",
    width: "100%",
    padding: "14px",
  },
  bnrChild: {
    display: "inline-flex",
    margin: "auto 0",
  },
  bnrIcon: {
    paddingLeft: "18px",
  },
  bnrMessage: {
    color: colorPalette.status.poisonCherry.hex,
    fontSize: "14px",
    fontVariationSettings: weightPalette?.medium?.variation,
    fontWeight: weightPalette?.medium?.amount,
    letterSpacing: "0.25px",
    lineHeight: "21px",
    paddingLeft: "10px",
  },
};

export { errorStyles };

import React, { Fragment, useContext, useEffect } from "react";
import { InlineAlert, TextField } from "gx-npm-ui";
import { UUID } from "gx-npm-lib";
import styles from "./sign-up-form-fields.styles.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { SignUpFormContext } from "../sign-up-form.context";
import ValidatePassword from "../validate-password/validate-password.component";

interface SignupJwtBody {
  email: string;
  initId: UUID;
  initTitle: string;
  invitedBy: string;
  surveyId: UUID;
}

const SignUpFormFields = () => {
  const { t } = useTranslation();
  const {
    email,
    firstName,
    lastName,
    password,
    isBreachedPassword,
    setFirstName,
    setLastName,
    setPassword,
    setEmail,
    termsPolicyAgreement,
    setErrorText,
    isValidPwd,
    setIsCompleteSignUpForm,
  } = useContext(SignUpFormContext);

  const deconstructToken = (tok: string): Readonly<SignupJwtBody> | null => {
    try {
      const tokenParts = tok.split(".");
      if (tokenParts.length <= 1) {
        return null;
      }
      const decodedString = window.atob(tokenParts[1]);
      return Object.freeze(JSON.parse(decodedString));
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const rawToken = new URLSearchParams(location.search).get("token");
    if (!rawToken) {
      return;
    }
    const tokenObj = deconstructToken(rawToken);
    if (tokenObj) {
      setEmail(tokenObj.email);
    }
  }, [setEmail]);

  useEffect(() => {
    if (firstName && lastName && isValidPwd && termsPolicyAgreement) {
      setIsCompleteSignUpForm(true);
    } else {
      setIsCompleteSignUpForm(false);
    }
  }, [firstName, lastName, isValidPwd, termsPolicyAgreement, setIsCompleteSignUpForm]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, inputType: string) => {
    const targetValue = event.target.value;
    if (inputType === "password") {
      setPassword(targetValue);
    } else if (inputType === "firstName") {
      setFirstName(targetValue);
    } else if (inputType === "lastName") {
      setLastName(targetValue);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, inputType: string) => {
    const validTypes = ["password"];
    if (validTypes.indexOf(inputType) > -1 && event?.key === "Enter") {
      handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    if (!firstName || !lastName || !password) {
      setErrorText(t("Please fill out all fields.") as string);
    } else {
      setErrorText("");
    }
  };

  return (
    <Fragment>
      <div className={styles.nameContainer}>
        <TextField
          label={t("First name *")}
          onChange={(e) => handleChange(e, "firstName")}
          onKeyDown={(e) => handleKeyDown(e, "firstName")}
          placeholder={t("First name *")}
          rootClassName={styles.txtFirstName}
          value={firstName}
        />
        <TextField
          label="Last name *"
          onChange={(e) => handleChange(e, "lastName")}
          onKeyDown={(e) => handleKeyDown(e, "lastName")}
          placeholder={t("Last name *")}
          value={lastName}
        />
      </div>
      <div className={styles.signUpCredFields}>
        <TextField
          disabled={true}
          label={t("Email")}
          placeholder={t("Email")}
          rootClassName={styles.txtEmail}
          value={email}
        />
        {isBreachedPassword && (
          <div className={classNames(styles.passwordErrorBannerContainer, "gx-error-inline-banner")}>
            <InlineAlert
              alertType={"error"}
              textMessage={t("Passwords cannot contain words that were in a previous password breach.")}
            />
          </div>
        )}
        <ValidatePassword />
      </div>
    </Fragment>
  );
};

export default SignUpFormFields;

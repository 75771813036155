import React, { useContext, useEffect, useState } from "react";
import queryString from "query-string";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { isValidResponse } from "gx-npm-lib";
import SignUpLeftDetail from "./components/signUp/leftDetail/sign-up-left-detail.component";
import SignUpForm from "./components/signUp/signUpForm/sign-up-form.component";
import { appStyles as styles } from "./styles";
import { postRequest } from "./lib/apiRequests";
import BadToken from "./components/errorPages/badToken";
import ExpiredToken from "./components/errorPages/expiredToken";
import SignUpLeftDetailSurveyRecipient from "./components/signUp/leftDetail/sign-up-left-detail-survey-recipient.component";
import { AppContext } from "./app.context";
import PropType from "prop-types";
import { OnboardingUser } from "./app.constants";

const useStyles = makeStyles(() => styles);
const propTypes = {
  onCompleteForm: PropType.func,
};
const SignUp = () => {
  const classes = useStyles();
  const [data, setData] = useState({});
  const [isValidToken, setIsValidToken] = useState();
  const [sysMsg, setSysMsg] = useState();
  const { token } = queryString.parse(window.location.search);
  const urlInviteValidateToken = "api/v2/auth/validate-signup-token";
  const { setOnboardingUser } = useContext(AppContext);
  useEffect(() => {
    async function postCheckToken() {
      const stringifyToken = JSON.stringify({ token });
      const response = await postRequest(urlInviteValidateToken, stringifyToken);

      if (isValidResponse(response)) {
        setIsValidToken(true);
        setData(deconstructToken(token));
      } else {
        const msg = response.data.systemMessage?.message || "";
        setSysMsg(msg);
        if (token && msg === "TOKEN_EXPIRED") {
          setData(deconstructToken(token));
        }
        setIsValidToken(false);
      }
    }

    if (token) {
      postCheckToken();
    }
  }, [token, urlInviteValidateToken]);

  const deconstructToken = (tok) => {
    const tokenParts = tok.split(".");
    if (tokenParts.length <= 1) {
      return null;
    }
    const decodedString = window.atob(tokenParts[1]);
    return JSON.parse(decodedString);
  };

  const { email, initId, initTitle, invitedBy, surveyId } = data;

  useEffect(() => {
    setOnboardingUser(surveyId ? OnboardingUser.SURVEY_RECIPIENT : OnboardingUser.EVALUATOR);
  }, [surveyId, setOnboardingUser]);
  if (isValidToken === false) {
    if (sysMsg === "TOKEN_EXPIRED") {
      return <ExpiredToken initTitle={initTitle} invitedBy={invitedBy} />;
    } else {
      return <BadToken />;
    }
  }

  return (
    <div className={classnames(classes.container, "gx-login-buysmart")}>
      <div className={classes.leftGrid}>
        <div className={classnames(classes.leftContainer, surveyId && classes.leftContainerSurveyRecipient)}>
          {isValidToken && !surveyId && <SignUpLeftDetail initTitle={initTitle} invitedBy={invitedBy} />}
          {isValidToken && surveyId && <SignUpLeftDetailSurveyRecipient />}
        </div>
      </div>
      <div className={classes.rightGrid}>
        <div className={classes.rightContainer}>
          {isValidToken && <SignUpForm surveyId={surveyId} email={email} initId={initId} token={token} />}
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = propTypes;
export default SignUp;

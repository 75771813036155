import { getAsyncRequest, postAsyncRequest } from "gx-npm-lib";

/**
 * wrap async get request for local dev work using env variables
 * @param {*} url url to make request to
 * @param {*} config configuration to use in request
 * @returns async get API request
 */
const getRequest = async (url, config = {}) => {
  return getAsyncRequest(`/${url}`, config);
};

/**
 * wrap async post request for local dev work using env variables
 * @param {*} url url to make request to
 * @param {*} payload body in request to send
 * @param {*} config configuration to use in request
 * @returns async post API request
 */
const postRequest = async (url, payload, config = {}) => {
  return postAsyncRequest(`/${url}`, payload, config);
};

export { getRequest, postRequest };

import React, { Fragment } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { appStyles as styles } from "../../styles";
import GartnerBuySmartLogo from "../logo";

const propTypes = {
  invitedBy: PropType.string,
  initTitle: PropType.string,
};
const useStyles = makeStyles(() => styles);
const ExpiredToken = ({ invitedBy = "", initTitle = "" }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classNames(classes.container, "gx-login-buysmart")}>
      <div className={classes.leftGrid}>
        <div className={classes.leftContainer}>
          <GartnerBuySmartLogo />
          <h2 className={classes.errorMsg}>
            {t("Your invitation link to join this evaluation has expired.")}
            {` `}
            {t("Please ask the owner to resend your invitation.")}
          </h2>
          <div>
            <div className={classes.divider} />
            {initTitle && (
              <Fragment>
                <p className={classNames(classes.errTitle, "p3")}>{t("Evaluation")}</p>
                <p className={classNames("bold", "p1")}>{initTitle}</p>
              </Fragment>
            )}
            {invitedBy && (
              <Fragment>
                <p className={classNames(classes.errTitle, "p3")}>{t("Owner")}</p>
                <p className={classNames("bold", "p1")}>{invitedBy}</p>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ExpiredToken.propTypes = propTypes;
export default ExpiredToken;

import { Fragment } from "react";
import LoginFormComponent from "./login-form/login-form.component";
import { LoginFormContextProvider } from "./login-form/login-form.context";
import WelcomeMessageComponent from "./welcome-message/welcome-message.component";
import styles from "./login-v2.styles.module.scss";

const LoginV2Component = () => {
  return (
    <Fragment>
      <div className={styles.loginContainer}>
        <div className={styles.contentWrapper}>
          <div className={styles.welcomeBackgroundWrapper}>
            <section className={styles.welcomeMessageContainer}>
              <WelcomeMessageComponent />
            </section>
          </div>
          <section className={styles.loginFormContainer}>
            <LoginFormContextProvider>
              <LoginFormComponent />
            </LoginFormContextProvider>
          </section>
        </div>
      </div>
      <div className={styles.triangle} />
    </Fragment>
  );
};

export default LoginV2Component;

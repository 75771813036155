import { UUID } from "gx-npm-lib";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type SignupJwtBody = {
  email: string;
  initId: UUID;
  initTitle: string;
  invitedBy: string;
  surveyId: UUID;
};

const useTokenParam = () => {
  const { search } = useLocation();
  const [initTitle, setInitTitle] = useState<string>("");
  const [invitedBy, setInvitedBy] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isValidToken, setIsValidToken] = useState<boolean>(true);
  const [token, setToken] = useState<string>("");
  const [surveyId, setSurveyId] = useState<string>("");

  useEffect(() => {
    const tokenParam = new URLSearchParams(search).get("token");
    if (!tokenParam) {
      setIsValidToken(false);
      return;
    }
    let tokenValues: SignupJwtBody | null = null;
    try {
      const tokenParts = tokenParam.split(".");
      if (tokenParts.length <= 1) {
        setIsValidToken(false);
        return;
      }
      const decodedString = window.atob(tokenParts[1]);
      tokenValues = Object.freeze(JSON.parse(decodedString));
    } catch (error) {
      setIsValidToken(false);
      return;
    }
    if (!tokenValues) {
      setIsValidToken(false);
      return;
    }
    setInitTitle(tokenValues.initTitle);
    setInvitedBy(tokenValues.invitedBy);
    setEmail(tokenValues.email);
    if (tokenValues.surveyId) {
      setSurveyId(tokenValues.surveyId);
    }
    setToken(tokenParam);
  }, [search]);

  return { initTitle, invitedBy, isValidToken, email, surveyId, token };
};

export default useTokenParam;

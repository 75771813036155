import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Container, makeStyles } from "@material-ui/core";
import { CloseIcon } from "gx-npm-icons";
import { Button, IconButton } from "gx-npm-ui";
import { mobileMsgClasses as styles } from "./styles";

const propTypes = { onClose: PropTypes.func };
const useStyles = makeStyles(() => styles);
const MobileMessage = ({ onClose = () => {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={classNames(classes.closeBtn)}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Container className={classes.container} maxWidth="sm">
        <p className={classNames(classes.heading, "bold")}>{t("Hold Up.")}</p>
        <p className={classNames(classes.emphasis, "bold")}>
          {t("We see you're on a mobile device - BuySmart is designed to be optimally experienced on larger screens.")}
        </p>
        <p className={classNames(classes.msg, "p1")}>
          {t(
            "Please understand that you may experience reduced or impaired functionality by continuing on your mobile device."
          )}
        </p>
        <Button onClick={onClose} rootClassName="btn-primary">
          {t("I Understand")}
        </Button>
      </Container>
    </Fragment>
  );
};

MobileMessage.propTypes = propTypes;
export default MobileMessage;

import { commonTheme } from "gx-npm-common-styles";
import { TypographyComponent, TypographyStyling } from "gx-npm-ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GartnerBuySmartLogoSVG from "../../../assets/images/gartner-buy-smart-logo.svg";
import styles from "./welcome-message.styles.module.scss";

const WelcomeMessageComponent = () => {
  const { t } = useTranslation();
  const [messageStyling, setMessageStyling] = useState<TypographyStyling>("h4");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width >= commonTheme.breakpoints.values.xl) {
        setMessageStyling("h1");
      } else if (width >= commonTheme.breakpoints.values.md) {
        setMessageStyling("h2");
      } else {
        setMessageStyling("h4");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.messageContainer}>
      <div className={styles.imgContainer}>
        <img alt={t("Gartner BuySmart logo")} src={GartnerBuySmartLogoSVG} />
      </div>
      <TypographyComponent boldness="medium" color="white" element="h1" styling={messageStyling}>
        {t("Streamline your team’s path to")}
        {` `}
        <br />
        <TypographyComponent color="tangerine" boldness="medium" element="span">
          {t("better technology evaluations")}
        </TypographyComponent>
        {` — `}
        {t("fueled by industry-leading Gartner experts and peer insight.")}
      </TypographyComponent>
    </div>
  );
};

export default WelcomeMessageComponent;

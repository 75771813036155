import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { ExclamationCircledIcon } from "gx-npm-icons";
import { errorStyles as styles } from "./error-banner.style";

const propTypes = { message: PropTypes.string };
const useStyles = makeStyles(() => styles);
const ErrorBanner = ({ message = "" }) => {
  const poisonCherry = colorPalette.status.poisonCherry.hex;
  const classes = useStyles();
  return (
    !!message && (
      <div className={classNames(classes.bnrContainer)}>
        <div className={classNames(classes.bnrChild, classes.bnrIcon)}>
          <ExclamationCircledIcon pathFill={poisonCherry} title={null} />
        </div>
        <div className={classNames(classes.bnrChild, classes.bnrMessage)}>{message}</div>
      </div>
    )
  );
};

ErrorBanner.propTypes = propTypes;
export default ErrorBanner;

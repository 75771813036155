import { colorPalette } from "gx-npm-common-styles";

const tangerineHex = colorPalette.brand.tangerine.hex;
const gartnerBlueHex = colorPalette.brand.gartnerBlue.hex;
const ironHex = colorPalette.neutrals.iron.hex;

const mobileMsgClasses = {
  container: {
    paddingLeft: "32px",
    paddingRight: "32px",
    paddingBottom: "32px",
  },
  heading: {
    fontSize: "48px",
    color: tangerineHex,
    margin: "50px 0",
  },
  emphasis: {
    color: gartnerBlueHex,
    fontSize: "24px",
    lineHeight: "130%",
    marginBottom: "40px",
  },
  msg: {
    color: ironHex,
    marginBottom: "80px",
  },
  closeBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "5px",
    marginRight: "16px",
  },
};

export { mobileMsgClasses };

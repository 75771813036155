import { createContext, ReactNode, useState } from "react";
import { ProfileObject, SignUpFormContextValue } from "./sign-up-form.types";

const SignUpFormContext = createContext<SignUpFormContextValue>({} as SignUpFormContextValue);

const SignUpFormContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [termsPolicyAgreement, setTermsPolicyAgreement] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const [isValidPwd, setIsValidPwd] = useState<boolean>(false);
  const [isBreachedPassword, setIsBreachedPassword] = useState<boolean>(false);
  const [isCompleteSignUpForm, setIsCompleteSignUpForm] = useState<boolean>(false);
  const [isCompleteProfileForm, setIsCompleteProfileForm] = useState<boolean>(false);
  const [initId, setInitId] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [surveyId, setSurveyId] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [initTitle, setInitTitle] = useState<string>("");
  const [invitedBy, setInvitedBy] = useState<string>("");
  const [profileObject, setProfileObject] = useState<ProfileObject>({});

  const contextValue = {
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    password,
    setPassword,
    showPassword,
    setShowPassword,
    termsPolicyAgreement,
    setTermsPolicyAgreement,
    isProcessing,
    setIsProcessing,
    errorText,
    setErrorText,
    isValidPwd,
    setIsValidPwd,
    isBreachedPassword,
    setIsBreachedPassword,
    isCompleteSignUpForm,
    setIsCompleteSignUpForm,
    isCompleteProfileForm,
    setIsCompleteProfileForm,
    initId,
    setInitId,
    invitedBy,
    setInvitedBy,
    token,
    setToken,
    surveyId,
    setSurveyId,
    confirmPassword,
    setConfirmPassword,
    page,
    setPage,
    initTitle,
    setInitTitle,
    profileObject,
    setProfileObject,
  };

  return <SignUpFormContext.Provider value={contextValue}>{props.children}</SignUpFormContext.Provider>;
};

export { SignUpFormContext, SignUpFormContextProvider };

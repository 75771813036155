import React, { Fragment } from "react";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import LoginButton from "./loginButton";
import { firstTimeLoginStyles as styles } from "./styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../../lib/feature-flags";
import { useFeatureFlag } from "gx-npm-ui";
import classNames from "classnames";

const propTypes = {
  firstName: PropType.string,
  lastName: PropType.string,
  onClickButtonLogin: PropType.func,
  onClickButtonSwitchAcct: PropType.func,
};
const useStyles = makeStyles(() => styles);
const FirstTimeLogin = ({
  firstName = "",
  lastName = "",
  onClickButtonLogin = () => {},
  onClickButtonSwitchAcct = () => {},
}) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classNames(classes.loggedIn, isFFGCOM3695 && classes.loggedInGCOM3695)}>
      <h2>
        {t("Welcome")}
        {firstName && lastName && (
          <Fragment>
            {`, `}
            <span className="blackBerry">{`${firstName} ${lastName}!`}</span>
          </Fragment>
        )}
      </h2>
      <h3 className={classes.secondHeading}>
        {t("As a Gartner client, you have instant access to Gartner BuySmart. Let's get you set up.")}
      </h3>
      <LoginButton onClick={onClickButtonLogin} />
      <div className={classes.switchAccounts}>
        {firstName && lastName && <p className="p3">{`${t("Not")} ${firstName} ${lastName}? `}</p>}
        <button onClick={onClickButtonSwitchAcct}>{t("Switch accounts")}</button>
      </div>
    </div>
  );
};

FirstTimeLogin.propTypes = propTypes;
export default FirstTimeLogin;

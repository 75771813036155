import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import styles from "./sign-up-v2.styles.module.scss";
import WelcomeMessageComponent from "./welcome-message/welcome-message.component";
import SignUpForm from "./sign-up-form/sign-up-form.component";
import { SignUpFormContextProvider } from "./sign-up-form/sign-up-form.context";
import { postAsyncRequest, UUID } from "gx-npm-lib";
import BadTokenComponent from "./error-pages/bad-token.component";
import ExpiredTokenComponent from "./error-pages/expired-token.component";

interface SignupJwtBody {
  email: string;
  initId: UUID;
  initTitle: string;
  invitedBy: string;
  surveyId: UUID;
}

const SignUpV2Component = () => {
  const [isValidToken, setIsValidToken] = useState<boolean>();
  const [sysMsg, setSysMsg] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    (async () => {
      const rawToken = new URLSearchParams(location.search).get("token");
      if (!rawToken) {
        setIsValidToken(false);
        return;
      }
      const tokenData = deconstructToken(rawToken);
      if (!tokenData) {
        setIsValidToken(false);
        return;
      }
      setIsLoading(true);
      try {
        const response = await postAsyncRequest("api/v2/auth/validate-signup-token", { token: rawToken });
        if (response?.status !== 200) {
          setIsValidToken(false);
          setSysMsg(response?.data?.systemMessage?.message);
        } else {
          setIsValidToken(true);
        }
      } catch (error) {
        setIsValidToken(false);
        setSysMsg("An error occurred while validating the token");
      } finally {
        setIsLoading(false);
      }
    })();
  }, [location]);

  const deconstructToken = (tok: string): Readonly<SignupJwtBody> | null => {
    try {
      const tokenParts = tok.split(".");
      if (tokenParts.length <= 1) {
        return null;
      }
      const decodedString = window.atob(tokenParts[1]);
      return Object.freeze(JSON.parse(decodedString));
    } catch (error) {
      setSysMsg("Failed to deconstruct token");
      return null;
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={classNames(styles.signUpContainer)}>
      <div className={styles.contentWrapper}>
        <div className={styles.welcomeBackgroundWrapper}>
          <section className={styles.welcomeMessageContainer}>
            {isValidToken && <WelcomeMessageComponent />}
            {!isValidToken && sysMsg === "TOKEN_EXPIRED" && <ExpiredTokenComponent />}
            {!isValidToken && sysMsg !== "TOKEN_EXPIRED" && <BadTokenComponent />}
          </section>
        </div>
        <SignUpFormContextProvider>
          <section className={styles.signUpFormContainer}>{isValidToken && <SignUpForm />}</section>
        </SignUpFormContextProvider>
      </div>
      <div className={styles.triangle} />
    </div>
  );
};

export default SignUpV2Component;

import React, { Fragment, useEffect, useState } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment, makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { CheckmarkSmallIcon, EyeIcon, EyeSlashIcon } from "gx-npm-icons";
import { TextField } from "gx-npm-ui";
import { signUpFormFieldsStyles as styles } from "./styles";

const propTypes = {
  onChange: PropType.func,
  onClickShowPassword: PropType.func,
  password: PropType.string,
  setIsValidPwd: PropType.func,
  showPassword: PropType.bool,
  isBreachedPassword: PropType.bool,
};
const useStyles = makeStyles(() => styles);
const ValidatePassword = ({
  onChange = (_event, _name) => {},
  onClickShowPassword = () => {},
  password = "",
  setIsValidPwd = (_isValid) => {},
  showPassword = false,
  isBreachedPassword = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isBetween8And40, setIsBetween8And40] = useState(false);
  const [hasOneLetter, setHasOneLetter] = useState(false);
  const [hasOneSpChar, setHasOneSpChar] = useState(false);
  const [passConfirmed, setPassConfirmed] = useState(false);
  const [isPwdOrGartner, setIsPwdOrGartner] = useState(false);
  const passColor = colorPalette.status.darkPear.hex;
  const failColor = colorPalette.neutrals.stone.hex;

  useEffect(() => {
    setIsBetween8And40(password.length >= 8 && password.length <= 40);
    setHasOneLetter(containsAnyLetter(password));
    setHasOneSpChar(containsAnySpecCharOrNum(password));
    setPassConfirmed(password === confirmPassword && password !== "");
    setIsPwdOrGartner(containsGartnerOrPassword(password));

    setIsValidPwd(
      password.length >= 8 &&
        password.length <= 40 &&
        containsAnyLetter(password) &&
        containsAnySpecCharOrNum(password) &&
        password === confirmPassword &&
        !containsGartnerOrPassword(password)
    );
  }, [password, setIsValidPwd, confirmPassword]);

  const containsAnyLetter = (str) => {
    return /[a-zA-Z]/.test(str);
  };

  const containsAnySpecCharOrNum = (str) => {
    const specialChars = /[`\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    return specialChars.test(str);
  };

  const containsGartnerOrPassword = (str) => {
    const gartnerOrPassword = /gartner|password/i;
    return gartnerOrPassword.test(str);
  };

  return (
    <Fragment>
      <TextField
        inputProps={{ autoComplete: "current-password" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={onClickShowPassword}>
                {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={t("Password *")}
        onChange={(event) => onChange(event, "password")}
        placeholder={t("Password *")}
        rootClassName={isBreachedPassword ? classes.txtPasswordError : classes.txtPassword}
        type={showPassword ? "text" : "password"}
        value={password || ""}
      />
      <p className={classNames(classes.pwdValidateItem, "p4")}>
        <CheckmarkSmallIcon fillPath={!isPwdOrGartner ? passColor : failColor} />
        <span className={classNames({ pwdIsNotValid: isPwdOrGartner }, classes.pwdValidateParam)}>
          {t("Cannot contain ‘password’ or ‘gartner’")}
        </span>
      </p>
      <p className={classNames(classes.pwdValidateItem, "p4")}>
        <CheckmarkSmallIcon fillPath={isBetween8And40 ? passColor : failColor} />
        <span className={classNames({ pwdIsNotValid: !isBetween8And40 }, classes.pwdValidateParam)}>
          {t("Between 8 and 40 characters")}
        </span>
      </p>
      <p className={classNames(classes.pwdValidateItem, "p4")}>
        <CheckmarkSmallIcon fillPath={hasOneLetter ? passColor : failColor} />
        <span className={classNames({ pwdIsNotValid: !hasOneLetter }, classes.pwdValidateParam)}>
          {t("At least one letter")}
        </span>
      </p>
      <p className={classNames(classes.pwdValidateItem, "p4")}>
        <CheckmarkSmallIcon fillPath={hasOneSpChar ? passColor : failColor} />
        <span className={classNames({ pwdIsNotValid: !hasOneSpChar }, classes.pwdValidateParam)}>
          {t("At least one number or special character")}
        </span>
      </p>
      <p className={classNames(classes.pwdValidateItem, "p4", classes.bottomClass)}>
        <CheckmarkSmallIcon fillPath={passConfirmed ? passColor : failColor} />
        <span className={classNames({ pwdIsNotValid: !passConfirmed }, classes.pwdValidateParam)}>
          {t("Passwords match")}
        </span>
      </p>
      <TextField
        inputProps={{ autoComplete: "current-password" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={onClickShowPassword}>
                {showPassword ? <EyeSlashIcon /> : <EyeIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        label={t("Confirm Password *")}
        onChange={(event) => setConfirmPassword(event.target.value)}
        placeholder={t("Confirm Password *")}
        rootClassName={isBreachedPassword ? classes.txtPasswordError : classes.txtPassword}
        type={showPassword ? "text" : "password"}
        value={confirmPassword || ""}
      />
    </Fragment>
  );
};

ValidatePassword.propTypes = propTypes;
export default ValidatePassword;

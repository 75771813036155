import styles from "./login.styles.module.scss";
import LeftDetail from "../leftDetail/leftDetail";
import LoginForm from "../loginForm/login-form.component";
import React from "react";

const Login = () => {
  return (
    <div className={`${styles.container} gx-login-buysmart`}>
      <div className={styles.leftGrid}>
        <div className={styles.leftContainer}>
          <LeftDetail />
        </div>
      </div>
      <div className={styles.rightGrid}>
        <div className={styles.rightContainer}>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { Fragment } from "react";
import PropType from "prop-types";
import { makeStyles } from "@material-ui/core";
import { InlineAlert, TextField } from "gx-npm-ui";
import { handleEvent } from "gx-npm-lib";
import { signUpFormFieldsStyles as styles } from "./styles";
import ValidatePassword from "./validatePassword";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const propTypes = {
  email: PropType.string,
  firstName: PropType.string,
  lastName: PropType.string,
  onChange: PropType.func,
  onClickShowPassword: PropType.func,
  onKeyDown: PropType.func,
  password: PropType.string,
  showPassword: PropType.bool,
  setIsValidPwd: PropType.func,
  isBreachedPassword: PropType.bool,
};
const useStyles = makeStyles(() => styles);
const SignUpFormFields = ({
  email = "",
  firstName = "",
  lastName = "",
  onChange = null,
  onClickShowPassword = null,
  onKeyDown = null,
  password = "",
  showPassword = false,
  setIsValidPwd = null,
  isBreachedPassword = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className={classes.nameContainer}>
        <TextField
          autoFocus
          label="First name *"
          onChange={(e) => handleEvent(onChange, e, "firstName")}
          onKeyDown={(e) => handleEvent(onKeyDown, e, "firstName")}
          placeholder="First name *"
          rootClassName={classes.txtFirstName}
          value={firstName}
        />
        <TextField
          label="Last name *"
          onChange={(e) => handleEvent(onChange, e, "lastName")}
          onKeyDown={(e) => handleEvent(onKeyDown, e, "lastName")}
          placeholder="Last name *"
          rootClassName={classes.txtLastName}
          value={lastName}
        />
      </div>
      <TextField disabled={true} label="Email" placeholder="Email" rootClassName={classes.txtEmail} value={email} />
      {isBreachedPassword && (
        <div className={classNames(classes.passwordErrorBannerContainer, "gx-error-inline-banner")}>
          <InlineAlert
            alertType={"error"}
            textMessage={t("Passwords cannot contain words that were in a previous password breach.")}
          />
        </div>
      )}
      <ValidatePassword
        onChange={(e) => handleEvent(onChange, e, "password")}
        showPassword={showPassword}
        password={password}
        setIsValidPwd={setIsValidPwd}
        onClickShowPassword={onClickShowPassword}
        isBreachedPassword={isBreachedPassword}
      />
    </Fragment>
  );
};

SignUpFormFields.propTypes = propTypes;
export default SignUpFormFields;

import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SignUpFormFields from "./sign-up-form-fileds/sign-up-form-fields.component";
import SignUpFormOptions from "./sign-up-form-options/sign-up-form-options.component";
import styles from "./sign-up-form.styles.module.scss";
import { SignUpFormContext } from "./sign-up-form.context";
import { TypographyComponent } from "gx-npm-ui";
import SignUpFormHeader from "./sign-up-form-header/sign-up-form-header.component";
import SignUpButton from "./sign-up-form-button/sign-up-form-button.component";
import SignUpProfileFields from "./sign-up-profile-fields/sign-up-profile-fields.component";
import { getAsyncRequest } from "gx-npm-lib";

const SignUpForm = () => {
  const { t } = useTranslation();
  const { page, setErrorText, setGcomData, surveyId } = useContext(SignUpFormContext);

  useEffect(() => {
    if (page === 1 && !surveyId) {
      (async () => {
        try {
          const response = await getAsyncRequest("/api/v3/data/profile");
          if (response.status !== 200 || !response.data.data) {
            throw new Error("Failed to fetch profile data");
          }
          setGcomData({
            companySize: response.data.data.companySize || [],
            industries: response.data.data.industries || [],
            functions: response.data.data.functions || [],
          });
        } catch (error) {
          setErrorText("Failed to fetch profile data. Please try again.");
        }
      })();
    }
  }, [page, setErrorText, setGcomData, surveyId]);

  return (
    <div className={styles.signUpForm}>
      <SignUpFormHeader />
      <form noValidate autoComplete="off">
        {page === 1 ? <SignUpFormFields /> : <SignUpProfileFields />}
        <TypographyComponent
          rootClassName={styles.required}
          color={"poisonCherry"}
          boldness={"regular"}
          styling={"p4"}
          element={"p"}
        >
          {t("*Required")}
        </TypographyComponent>
        {page === 1 && <SignUpFormOptions />}
        <SignUpButton />
      </form>
    </div>
  );
};

export default SignUpForm;

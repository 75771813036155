import React, { Fragment } from "react";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { leftDetailClasses as styles } from "../../leftDetail/styles";
import GartnerBuySmartLogo from "../../logo";

const propTypes = { initTitle: PropType.string, invitedBy: PropType.string };
const useStyles = makeStyles(() => styles);
const SignUpLeftDetailSurveyRecipient = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <GartnerBuySmartLogo />
      <h1 className={classes.description}>
        {t("Join hundreds of other vendors")}
        <span className={classes.tangerine}> {t("engaging with prospects")}</span>
        {t(" on BuySmart.")}
      </h1>
    </Fragment>
  );
};

SignUpLeftDetailSurveyRecipient.propTypes = propTypes;
export default SignUpLeftDetailSurveyRecipient;

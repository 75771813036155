import { colorPalette } from "gx-npm-common-styles";

const signUpFormClasses = {
  signUpForm: {
    backgroundColor: colorPalette.basic.white.hex,
    borderRadius: "8px",
    boxShadow: "0px 8px 8px rgba(20, 19, 18, 0.16)",
    padding: "40px",
    "&.first-time": {
      padding: "60px 64px",
      transition: "all 0.5s",
      "@media (max-width: 1439px)": {
        padding: "48px 40px",
        "& h2": {
          fontSize: "28px",
          lineHeight: "36.4px",
        },
        "& h3": {
          fontSize: "24px",
        },
      },
    },
    "@media (max-width: 1439px)": {
      padding: "56px 42px 48px 40px",
    },
    "@media (max-width: 413px)": {
      padding: "32px 24px 40px 24px",
    },
  },
};

const signUpButtonStyles = {
  signUpButtonContainer: {
    alignItems: "center",
    display: "flex",
    marginTop: "56px",
    "& .gx-btn-loader": {
      marginLeft: "auto",
    },
  },
};

const formHeaderStyles = {
  signUpHeading: {
    marginBottom: 40,
    "& $title": {
      paddingBottom: "8px",
    },
    "& $signUpHeadingLogin": {
      alignItems: "center",
      display: "flex",
    },
    "& .gx-text-link": {
      marginRight: "4px",
    },
  },
  errorContainer: {
    marginBottom: "24px",
    "@media (max-width: 1439px)": {
      marginBottom: "32px",
    },
  },
  signUpHeadingLogin: {},
  title: {
    "@media (max-width: 413px)": {
      "&.p1": {
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.25px",
      },
    },
  },
};

const signUpFormFieldsStyles = {
  nameContainer: {
    display: "flex",
    marginBottom: "36px",
    "@media (max-width: 413px)": {
      flexDirection: "column",
      gap: "32px",
      marginBottom: "32px",
      "& $txtFirstName": {
        marginRight: "0px",
      },
    },
  },
  txtFirstName: {
    marginRight: "24px",
  },
  txtEmail: {
    marginBottom: "36px",
    "@media (max-width: 413px)": {
      marginBottom: "32px",
    },
  },
  txtPassword: {
    marginBottom: "10px",
  },
  txtPasswordError: {
    marginBottom: "10px",
    "& fieldset": {
      borderColor: colorPalette.status.cherry.hex + " !important",
      borderWidth: "2px !important",
    },
    "& .gx-text-field-container label": {
      color: colorPalette.status.cherry.hex + " !important",
    },
  },
  bottomClass: {},
  pwdValidateItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "5px",
    "&$bottomClass": {
      marginBottom: "25px",
    },
  },
  pwdValidateParam: {
    display: "block",
    color: colorPalette.neutrals.carbon.hex,
    "&.pwdIsNotValid": {
      color: colorPalette.neutrals.iron.hex,
    },
  },
  passwordErrorBannerContainer: {
    "& .inline-alert": {
      boxShadow: "none !important",
      marginTop: "0px",
      marginBottom: "20px",
      height: "66px",
      "& p": {
        fontWeight: 500,
      },
      "& svg": {
        marginBottom: "10px",
      },
    },
  },
};

const signUpFormOptionsStyles = {
  agreeTerms: {
    marginLeft: "5px",
    marginRight: "0px",
    "@media (max-width: 1439px)": {
      marginTop: "4px",
    },
  },
};

export { formHeaderStyles, signUpButtonStyles, signUpFormClasses, signUpFormFieldsStyles, signUpFormOptionsStyles };

import React from "react";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";

import { ButtonLoader } from "gx-npm-ui";

import classNames from "classnames";

const propTypes = { onClick: PropType.func, isLoading: PropType.bool };
const LoginButton = ({ onClick = () => {}, isLoading = false }) => {
  const { t } = useTranslation();
  return (
    <ButtonLoader
      btnRootClassName={classNames("gx-dragon-login-btn")}
      btnClass="btn-primary"
      onClick={onClick}
      isLoading={isLoading}
      ariaLabel={"Get started"}
    >
      {t("Get started")}
    </ButtonLoader>
  );
};

LoginButton.propTypes = propTypes;
export default LoginButton;

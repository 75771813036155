import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SignUpFormContext } from "../sign-up-form.context";
import { Select, TextField } from "gx-npm-ui";
import styles from "./sign-up-profile-fields.styles.module.scss";
import { getAsyncRequest } from "gx-npm-lib";
import { CompanySize, GcomData, Industry, ProfileFunction, ProfileObject, Role } from "../sign-up-form.types";

const SignUpProfileFields = () => {
  const { t } = useTranslation();
  const { setErrorText, setIsCompleteProfileForm, profileObject, setProfileObject } = useContext(SignUpFormContext);

  const [gcomData, setGcomData] = useState<GcomData>({
    companySize: [],
    industries: [],
    functions: [],
  });

  const [selectedFunctionRoles, setSelectedFunctionRoles] = useState<Role[]>([]);

  const fetchProfileData = async () => {
    //TODO Update URL with the new endpoint
    const url = "/api/v3/data/profile";
    const response = await getAsyncRequest(url);
    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error("Failed to fetch profile data");
    }
  };

  useEffect(() => {
    const { companySize, industry, function: func, role, title } = profileObject as ProfileObject;
    if (companySize && industry && func && role && title) {
      setIsCompleteProfileForm(true);
    } else {
      setIsCompleteProfileForm(false);
    }
  }, [profileObject, setIsCompleteProfileForm]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchProfileData();
        setGcomData(response.data);
      } catch (error) {
        setErrorText(t("Failed to fetch profile data. Please try again.") as string);
      }
    })();
  }, [setErrorText, t]);

  const handleChange = (
    event: React.ChangeEvent<{ value: string }> | React.ChangeEvent<HTMLInputElement>,
    inputType: string
  ) => {
    const targetValue = (event.target as HTMLInputElement).value as string;
    let selectedOption: CompanySize | Industry | ProfileFunction | Role | undefined;

    switch (inputType) {
      case "companySize":
        selectedOption = gcomData.companySize.find((size) => size.name === targetValue);
        setProfileObject((prevProfileObject) => ({
          ...prevProfileObject,
          companySize: selectedOption ? { id: selectedOption.id, name: targetValue } : targetValue,
        }));
        break;
      case "industry":
        selectedOption = gcomData.industries.find((industry) => industry.name === targetValue);
        setProfileObject((prevProfileObject) => ({
          ...prevProfileObject,
          industry: selectedOption ? { id: selectedOption.id, name: targetValue } : targetValue,
        }));
        break;
      case "function":
        selectedOption = gcomData.functions.find((func) => func.name === targetValue);
        if (selectedOption) {
          setSelectedFunctionRoles((selectedOption as ProfileFunction).roles);
          setProfileObject((prevProfileObject) => ({
            ...prevProfileObject,
            function: { id: selectedOption?.id, name: targetValue },
            role: undefined,
          }));
        }
        break;
      case "role":
        selectedOption = selectedFunctionRoles.find((role) => role.name === targetValue);
        setProfileObject((prevProfileObject) => ({
          ...prevProfileObject,
          role: selectedOption ? { id: selectedOption.id, name: targetValue } : targetValue,
        }));
        break;
      default:
        setProfileObject((prevProfileObject) => ({
          ...prevProfileObject,
          [inputType]: targetValue,
        }));
        break;
    }
  };

  return (
    <Fragment>
      <div className={styles.profileContainer}>
        <Select
          disabled={gcomData.companySize?.length === 0}
          items={gcomData.companySize.map((size) => size.name)}
          label={`${t("Company size")}*`}
          menuHeight="210px"
          onChange={(e: React.ChangeEvent<{ value: string }>) => handleChange(e, "companySize")}
          popOverPaperClassName="gx-profile-select-paper-popover"
          rootClassName="gx-profile-input"
        />

        <Select
          disabled={gcomData.industries?.length === 0}
          items={gcomData.industries.map((industry) => industry.name)}
          label={`${t("Industry")}*`}
          menuHeight="210px"
          onChange={(e: React.ChangeEvent<{ value: string }>) => handleChange(e, "industry")}
          popOverPaperClassName="gx-profile-select-paper-popover"
          rootClassName="gx-profile-input"
        />

        <Select
          disabled={gcomData.functions?.length === 0}
          items={gcomData.functions.map((func) => func.name)}
          label={`${t("Function")}*`}
          menuHeight="210px"
          onChange={(e: React.ChangeEvent<{ value: string }>) => handleChange(e, "function")}
          popOverPaperClassName="gx-profile-select-paper-popover"
          rootClassName="gx-profile-input"
        />

        <Select
          disabled={selectedFunctionRoles.length === 0}
          items={selectedFunctionRoles.map((role) => role.name)}
          label={`${t("Role")}*`}
          menuHeight="210px"
          onChange={(e: React.ChangeEvent<{ value: string }>) => handleChange(e, "role")}
          popOverPaperClassName="gx-profile-select-paper-popover"
          rootClassName="gx-profile-input"
        />

        <TextField
          label="Title*"
          placeholder={t("Title*")}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, "title")}
        />
      </div>
    </Fragment>
  );
};

export default SignUpProfileFields;

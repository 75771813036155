import React from "react";
import { BuySmartLogo, GartnerLogo } from "gx-npm-icons";
import { makeStyles } from "@material-ui/core";
import { logoStyles as styles } from "./styles";

const useStyles = makeStyles(() => styles);
const GartnerBuySmartLogo = () => {
  const classes = useStyles();
  return (
    <div className={classes.logo}>
      <GartnerLogo height="24" width="108" />
      <span className={classes.buySmartLogo}>
        <BuySmartLogo height="26" variant="white" width="141" />
      </span>
    </div>
  );
};

export default GartnerBuySmartLogo;

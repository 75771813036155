import React from "react";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { ButtonLoader } from "gx-npm-ui";
import { signUpButtonStyles as styles } from "./styles";

const propTypes = {
  disabled: PropType.bool,
  isProcessing: PropType.bool,
  onClick: PropType.func,
};
const useStyles = makeStyles(() => styles);
const SignUpButton = ({ disabled = true, isProcessing = false, onClick = () => {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.signUpButtonContainer}>
      <p className="p4">{t("*Required")}</p>
      <ButtonLoader
        ariaLabel={t("sign up button")}
        btnClass="btn-primary"
        disabled={disabled}
        isLoading={isProcessing}
        onClick={onClick}
      >
        {t("Sign up")}
      </ButtonLoader>
    </div>
  );
};

SignUpButton.propTypes = propTypes;
export default SignUpButton;

import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { leftDetailClasses as styles } from "./styles";
import GartnerBuySmartLogo from "../logo";

const useStyles = makeStyles(() => styles);
const LeftDetail = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <GartnerBuySmartLogo />
      <h1 className={classes.description}>
        {t("Streamline your team’s path to ")}
        {` `}
        <br className={classes.brTag} />
        <span className={classes.tangerine}>{t("better technology evaluations")}</span>
        {` — `}
        {t("fueled by industry-leading Gartner experts and peer insights.")}
      </h1>
    </Fragment>
  );
};

export default LeftDetail;

import React from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Checkbox, useFeatureFlag } from "gx-npm-ui";
import { loginFormOptionsStyles as styles } from "./styles";
import { GCOM_3695_ctaButtonColorUpdate } from "../../lib/feature-flags";

const propTypes = {
  isRemembering: PropType.bool,
  onChangeRemember: PropType.func,
  urlRecoverLink: PropType.string,
};
const useStyles = makeStyles(() => styles);
const LoginFormOptions = ({ isRemembering = false, onChangeRemember = (_isChecked) => {}, urlRecoverLink = "" }) => {
  const isFFGCOM3695 = useFeatureFlag(GCOM_3695_ctaButtonColorUpdate);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classNames(classes.loginOptions)}>
      <Checkbox
        checked={isRemembering}
        inputProps={{ "aria-label": t("Remember me") }}
        label={t("Remember me")}
        name="remember"
        onChange={(event) => onChangeRemember(!!event.target.checked)}
        rootClassName={classes.rememberMe}
      />
      <div className={classNames(classes.forgotPassword)}>
        <p className="p4">
          <a
            {...(isFFGCOM3695 && { className: classes.resetPasswordLinkGCOM3695 })}
            aria-label={t("Forgot Password")}
            href={urlRecoverLink}
          >
            {`${t("Forgot Password")}?`}
          </a>
        </p>
      </div>
    </div>
  );
};

LoginFormOptions.propTypes = propTypes;
export default LoginFormOptions;
